import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";
import { Link } from "gatsby";

import imgHeader from "../../assets/img/realisations/right_0.jpg";
import SEO from "../../components/seo";

export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="Right Ink | Agence digitale | MDS Digital Agency"
    description="Nous avons effectué une refonte graphique de l’intégralité du site internet de l’agence de traduction professionnelle Right Ink."
    keywords="Right Ink"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/mise-a-jour-contenu/">Mise à jour de contenu</Link></span>
          <span className={workStyle.badge}><Link to="/services/copywriting/">Copywriting</Link></span>
          <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
          <span className={workStyle.badge}><Link to="/services/developpement/">Développement</Link></span>
      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Traduction</p>
        <h3>Localisation</h3>
        <p>Mons</p>

   
      </div>
      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a
          className={workStyle.external_link}
            href="http://www.right-ink.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            right-ink.com
          </a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Right Ink</h2>
      <p>
      MDS a effectué une refonte complète du site de Right Ink en créant une <strong>connexion directe avec le portail client</strong> MyRITO.
      </p>
      <p> 
      Le <strong>référencement</strong> de ce site sur les moteurs de recherche est très performant et offre au site internet de Right Ink une excellente visibilité. 
      </p>
      <p>
      Sur le site, les prospects ou clients peuvent effectuer une <strong>demande détaillée de devis en ligne</strong>.
      </p>
      <p>
      Les traducteurs peuvent quant à eux <strong>créer un profil</strong> très précis et être ainsi répertoriés dans la base de données de traducteurs de Right Ink.
      </p>
      <p>
      Le <strong>blog</strong> permet à la société de partager avec l’aide de MDS ses dernières actualités.
      </p>
      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
